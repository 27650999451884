import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ResourceFormService, ResourceListPane } from '@smartsoftware/reflex-core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReflexEnvironment as environment } from '@smartsoftware/reflex-core';

import {
    WIP,
    Document,
    Order,
    OrderStatus,
    Order_Service,
    UserPermission_Service,
    OrderHistoryUserFilter,
    SuccessOrderDataSource,
    PagedResourceListComponent,
    Division,
    SuccessAccountPermissionNodeName,
    SystemConfig_service,
    ServiceType,
    ServiceCategory,
    ServiceType_Service,
    ServiceCategory_Service,
    Service_Service,
    Service,
    ServiceAddress_Service,
    ServiceAddress,
    ServiceTypes,
    Telemetry_Service,
    TelemetryEventType,
    VendorAccount_Service,
    VendorAccount
} from 'legalreflex-lib';
import { animate, trigger, state, style, transition } from '@angular/animations';
import { FormGroup, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { BreadCrumbService } from '../../../components/breadcrumb-bar/breadcrumb-service';
import { Title } from '@angular/platform-browser';
import { SelectVendorAccountDialog } from '../../../components/selectVendorAccount/selectVendorAccount';

// moved Division enum to vendor model 
// const enum Division {
//     DATATRAC = "Datatrac",
//     ACCLAIM = "Acclaim",
//     TRISTAR = "Tristar",
//     TRISTAR_FDS = "Tristar FDS",
//     TRISTAR_FLI = "Tristar FLI",
//     TRISTAR_FLI_CG = "Tristar FLI CG",
// }

const ORDER_DIVISIONS: Array<any> = [
    {description: "Court & Process", value: Division.DATATRAC, checked: false, control: "courtAndProcess"},
    {description: "Depositions", value: Division.ACCLAIM, checked: false, control: "depositions" },
    {description: "Digital", value: Division.TRISTAR_FDS, checked: false, control: "digital"},
    {description: "Investigations", value: Division.TRISTAR_FLI, checked: false, control: "investigations"},
    // {description: "Investigations (CaseGator)", value: Vendor.TRISTAR_FLI_CG, checked: false, control: "courtAndProcess"},
    {description: "Records", value: Division.TRISTAR, checked: false, control: "records"},
    {description: "Discovery", value: Division.EDISCOVERY, checked: false, control: "eDiscovery"}
];

const INCLUDE_DRAFTS = {description: "Include Draft Orders", value: OrderStatus.DRAFT, checked: false, control: "includeDrafts"};

const DEFAULT_SEARCH = {
    corpId: '',
    placedByEmail: '',
    requestedBy: '',
    orderNumber: '',
    clientMatterNumber: '',
    caseNumber: '',
    displayStatus: '',
    includeDrafts: false,
    startOrderDate: null,
    endOrderDate: null,
    courtAndProcess: false,
    depositions: false,
    digital: false,
    investigations: false,
    records: false,
    eDiscovery: false
};

@Component({
    // selector: 'orderTable',
    templateUrl: './list.html',
    styleUrls: ['./list.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('filterExpand', [
            state('collapsed', style({width: '0px', minWidth: '0'})),
            state('expanded', style({width: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ],
})
export class OrderList extends PagedResourceListComponent<Order> {

    @ViewChild(MatSort) sort?: MatSort;
    @ViewChild('filter') filterPane: ResourceListPane = new ResourceListPane();
    public filterCollapsed: boolean = sessionStorage.getItem("filterCollapsed") == 'true';
    public loading: boolean = true;

    public filingServices: Service[] = [];
    public filingTypes: ServiceType[] = [];
    public filingCategories: ServiceCategory[] = [];
    public accountId: string = '';
    public sortedData: MatTableDataSource<Order>
    public expandedEntity: Order | null = null;
    // public expandedFilter: Boolean = false;
    public columnsToDisplay = ['expand', 'division', 'requestedBy', 'orderNumber', 'orderDate', 'clientMatterNumber', 'caseNumber', 'displayStatus'];

    public orderDocuments: Array<Document> = [];

    public standardFilters: OrderHistoryUserFilter = {};
    // public orderFilters: OrderHistoryUserFilter = {}; 

    public orderDivisions = ORDER_DIVISIONS;
    public includeDrafts = INCLUDE_DRAFTS;
    
    public collectedDocuments: Map<Order, Document[]> = new Map();


    public WIP : boolean = WIP();
	public recordsRetrievalAccounts: Array<VendorAccount> = [];

    public orderSearchFormGroup: FormGroup = this.fb.group(DEFAULT_SEARCH, {
        validator: [OrderList.createOrderDateRangeValidator]
    });

    constructor(
        public dataSource: SuccessOrderDataSource,
        protected auth: OidcSecurityService,
        public resourceService: Order_Service,
        private userPermissionService: UserPermission_Service,
        private filingServiceService: Service_Service,
        private serviceAddressService: ServiceAddress_Service,
        public sysConfigService: SystemConfig_service,
        protected route: ActivatedRoute,
        protected router: Router,
        protected dialog: MatDialog,
        protected resourceFormService: ResourceFormService,
        protected fb: FormBuilder,
		private breadCrumbService: BreadCrumbService,
        public pageTitleService: Title,
		public telemetryService: Telemetry_Service,
        public vendorAccountService: VendorAccount_Service
    ) {
        super(dataSource, resourceService, dialog, route, router);

        this.pageTitleService.setTitle("FirstConnect - Order History");
        this.filterFormGroup = this.fb.group(DEFAULT_SEARCH, {
            validator: [OrderList.createOrderDateRangeValidator]
        });


        this.sort = new MatSort();
        this.sort.disableClear = true;
        this.sortedData = new MatTableDataSource(this.dataSource.data);
        this.sortedData.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase().trim();
            }
          
            return data[sortHeaderId];
        };

        this.breadCrumbService.breadcrumbs.next([
            { label: 'Orders', url: '/order'},
            { label: 'History' }
        ]);

        this.breadCrumbService.actions.next([
            { label: 'Refresh', action:this.clearFilters.bind(this,true), icon:'refresh'},
            { label: 'New Order', routerLink: "/order/new", isPrimary: true, icon: 'library_add'}
        ]);
    }
    
    sortData(event: any){
        if(this.dataSource.sort){
            this.dataSource.sort.active = event.active
            this.dataSource.sort.direction = event.direction            
        }
        this.dataSource.reload();
    }    

    expandEntity(entity:Order) {
        this.expandedEntity = this.expandedEntity === entity ? null : entity;
        this.loadDocuments(entity);
        this.loadInfo(entity);
    }

    loadDocuments(entity: Order) {
        if (!this.collectedDocuments.has(entity)) {
            this.resourceService.findDocumentsByOrderId(entity.uuid)
                .subscribe(docs => {
                    this.collectedDocuments.set(entity, docs);
                });
        }
    }

    loadInfo(entity: Order) {
        let entityType: ServiceType = new ServiceType();
        let entityCategory: ServiceCategory = new ServiceCategory();
        let entityService = this.filingServiceService.services.value.find((currentService)=>{
            let currentCategory = currentService.categories.find((category)=>{
                let type = category.types.find((type)=>{
                    return type.uuid == entity.filingServiceType_uuid;
                })
                if(type)
                    entityType = type;
                return !!type;
            })
            if(currentCategory)
                entityCategory = currentCategory.category;
            return !!currentCategory;
        })
        if(!entity.deliveryAddress){
            if(entity.delivery_ServiceAddress_uuid){
                this.serviceAddressService.get(entity.delivery_ServiceAddress_uuid).subscribe((res)=>{
                    let address = res;
                    entity.deliveryLocation = address.name;
                    entity.deliveryAddress = (address.address || ' ') + ' ' + (address.city || ' ') + ' ' + (address.state || ' ') + ' ' + (address.zip || ' ');
                })
            } else {
                this.serviceAddressService.getDeliveryAddressesByOrderId(entity.uuid).subscribe((res)=>{
                    let address = res[0] || new ServiceAddress();
                    entity.deliveryLocation = address.name;
                    entity.deliveryAddress = (address.address || ' ') + ' ' + (address.city || ' ') + ' ' + (address.state || ' ') + ' ' + (address.zip || ' ');
                })
            }
        }
    }

    listOrderDocuments(entity:Order) {
        this.resourceService.findDocumentsByOrderId(entity.uuid)
            .subscribe(docs => {
                this.orderDocuments = docs;
            });
    }

    clearFilters(search?: boolean) {
        this.filterFormGroup?.reset(DEFAULT_SEARCH);
        if(this.standardFilters.byUser)
            this.filterFormGroup?.controls['placedByEmail'].setValue(this.standardFilters.placedByEmail)
        else
            this.filterFormGroup?.controls['corpId'].setValue(this.standardFilters.corpId)

        if(search)
            this.search();
    }

    getErrors(formControlName: string) {
        const errorMessages : {[key:string]:string} = {
            orderDateRange: "Start date is after end date",
            missingOrderStart: "Start date is required for range"
        };

        let errorMessage : string = '';
        
        Object.keys(this.filterFormGroup?.get(formControlName)?.errors || {})?.forEach((error:string) => {
            if(errorMessages[error]) {
                errorMessage = errorMessages[error];
            }
        });

        return errorMessage;
    }

    static createOrderDateRangeValidator(form: FormGroup) {
        const start = form.get('startOrderDate');
        const end = form.get('endOrderDate');

        if(start && !start.value && end?.value) {
            start.setErrors({missingOrderStart: true});
            return {missingOrderStart: true};
        }

        if(start && start.value && end && end.value) {
            const isRangeValid = (end.value.getTime() - start.value.getTime() > 0);

            start.setErrors(isRangeValid ? null : {orderDateRange:true});
            return isRangeValid ? null : {orderDateRange:true};
        }

        start?.setErrors(null);
        return null;
    }

    private divisionHelper(control: AbstractControl, vendor: string) {
        if(this.filterFormGroup && !this.filterFormGroup.controls['division']) {
            // division is not declared
            if(control.value) {
                // box is checked, array does not exist => create array with vendor
                this.filterFormGroup.addControl('division', new FormControl([vendor]))
            } 
            // else { 
                // box is not checked, array does not exist => do nothing 
            // }
        } else if(this.filterFormGroup) {
            if(this.filterFormGroup.controls['division'].value == null)
                this.filterFormGroup.controls['division'].setValue([]);
            // find the index for vendor
            let divisions: string[] = this.filterFormGroup.controls['division'].value ? this.filterFormGroup.controls['division'].value : []
            const index = divisions.findIndex((vendorName: string) => vendor == vendorName);    
            if(control.value && index == -1) {
                divisions.push(vendor);
                // box is checked, vendor not found in array => add vendor 
                this.filterFormGroup.controls['division'].setValue(divisions);
            // else if(control?.value && index > -1) {
                // box is checked, vendor found in array =>  do nothing
            
            } else if(!control.value && index > -1) {
                divisions.splice(index,1);
                // box is not checked, vendor found in array => remove vendor
                this.filterFormGroup.controls['division'].setValue(divisions);
            } 
            // } else {
                // box is not checked, vendor not found in array => do nothing
            // }
        } 

    }

    updateOrderFilters() {
        if(!this.dataSource.filters){
            if(this.userPermissionService.canDo(SuccessAccountPermissionNodeName.view_orders_for_firm_accounts)) {
                this.standardFilters = {
                    corpId: this.userPermissionService.loggedInUser?.corpId,
                    byCorp: true
                };
                this.filterFormGroup?.controls['corpId'].setValue(this.userPermissionService.loggedInUser?.corpId)
            } else {
                this.standardFilters = {
                    placedByEmail: this.userPermissionService.loggedInUser?.email,
                    byUser: true
                };
                this.filterFormGroup?.controls['placedByEmail'].setValue(this.userPermissionService.loggedInUser?.email)
            }
        }
        Object.keys(this.filterFormGroup!.controls).map((controlName) => {
            const control = this.filterFormGroup!.get(controlName);

            switch(controlName) {
                case "requestedBy":
                case "orderNumber":
                case "clientMatterNumber":
                case "caseNumber":
                case "displayStatus":
                    // guard to catch any empty search fields
                    if(!control?.value || control?.value.trim() == '') {
                        // delete this.orderFilters[controlName];
                    } else {
                        // this.orderFilters[controlName] = control?.value;
                    }
                    break;
                case "includeDrafts":
                    if(control?.value) {
                        this.filterFormGroup!.controls['orderStatus'] ? 
                            this.filterFormGroup!.controls['orderStatus'].setValue(OrderStatus.STUB) :
                            this.filterFormGroup!.addControl('orderStatus', new FormControl(OrderStatus.STUB));
                        this.filterFormGroup!.controls['negateOrderStatusMatch'] ?
                            this.filterFormGroup!.controls['negateOrderStatusMatch'].setValue(true) :
                            this.filterFormGroup!.addControl('negateOrderStatusMatch', new FormControl(true));                        ;

                        // this.filterFormGroup?.removeControl('orderStatus');
                        // this.filterFormGroup?.removeControl('negateOrderStatusMatch');

                        // delete this.orderFilters['orderStatus'];
                        // delete this.orderFilters.negateOrderStatusMatch;
                    } else {
                        this.filterFormGroup!.controls['orderStatus'] ? 
                            this.filterFormGroup!.controls['orderStatus'].setValue([OrderStatus.DRAFT,OrderStatus.STUB]) :
                            this.filterFormGroup!.addControl('orderStatus', new FormControl([OrderStatus.DRAFT,OrderStatus.STUB]));
                        this.filterFormGroup!.controls['negateOrderStatusMatch'] ?
                            this.filterFormGroup!.controls['negateOrderStatusMatch'].setValue(true) :
                            this.filterFormGroup!.addControl('negateOrderStatusMatch', new FormControl(true));                        ;
                        // this.orderFilters['orderStatus'] = OrderStatus.DRAFT;
                        // this.orderFilters.negateOrderStatusMatch = true;
                    }
                    break;
                case "courtAndProcess":
                    if(control)
                        this.divisionHelper(control, Division.DATATRAC)
                    break;
                case "depositions":
                    if(control)
                        this.divisionHelper(control, Division.ACCLAIM)
                    break;
                case "digital":
                    if(control)
                        this.divisionHelper(control, Division.TRISTAR_FDS)
                    break;
                case "investigations":
                    if(control) {
                        // investigations has two vendors, so we add/remove both as needed
                        this.divisionHelper(control, Division.TRISTAR_FLI);
                        this.divisionHelper(control, Division.TRISTAR_FLI_CG);
                    }
                    break;
                case "records":
                    if(control)
                        this.divisionHelper(control, Division.TRISTAR);
                    break;
                case "eDiscovery":
                    if(control)
                        this.divisionHelper(control, Division.EDISCOVERY);
                    break;
                case "startOrderDate":
                    if(control?.pristine && !control?.value) {
                        // likely the result of search run after clear
                        // delete this.orderFilters.orderDate;
                    }
                    else if(control?.dirty && control?.value) {
                        let startDate : Date = control?.value;

                        // strip out the time part of the date.
                        startDate.setHours(0, 0, 0, 0);
                        // if(this.orderFilters.orderDate) 
                        //     this.orderFilters.orderDate[0] = startDate;
                        // else 
                        //     this.orderFilters.orderDate = [startDate];
                    }
                    break;
                case "endOrderDate": 
                    if(control?.dirty && control?.value) {

                        let endDate : Date = control?.value;

                        // strip out the time part of the date.
                        endDate.setHours(0, 0, 0, 0);
                        let previousDate = new Date(endDate); 
                        previousDate.setDate(previousDate.getDate() - 1); 

                        // if(this.orderFilters.orderDate && this.orderFilters.orderDate.length > 0) {
                        //     this.orderFilters.orderDate[1] = endDate;
                        // } else {
                        //     // this should be caught by the validator but just in case, 
                        //     // this will keep the query from doing weird things
                        //     this.orderFilters.orderDate = [previousDate, endDate];
                        // }

                    } 
                    // else do nothing to the orderFilters, because we might have valid data in the array 
                    break;
            }
        });
    }
    
    pageChange(event:any){
        this.updateOrderFilters();
        this.dataSource.filters = {}
        Object.keys(this.filterFormGroup!.controls).map((control)=>{
            let filter : {[key:string]:any} = {[control]: this.filterFormGroup?.controls[control].value}
            Object.assign(this.dataSource.filters, filter)
        })
    }
    
    search(){
        // make sure form is valid before searching
        if(this.filterFormGroup!.invalid) return;
        if(this.filterFormGroup!.controls['corpId'] && this.filterFormGroup!.controls['placedByEmail']){
            if(this.userPermissionService.canDo(SuccessAccountPermissionNodeName.view_orders_for_firm_accounts)) {
                this.standardFilters = {
                    corpId: this.userPermissionService.loggedInUser?.corpId,
                    byCorp: true
                };
                this.filterFormGroup?.controls['corpId'].setValue(this.userPermissionService.loggedInUser?.corpId)
            } else {
                this.standardFilters = {
                    placedByEmail: this.userPermissionService.loggedInUser?.email,
                    byUser: true
                };
                this.filterFormGroup?.controls['placedByEmail'].setValue(this.userPermissionService.loggedInUser?.email)
            }
        }
        this.updateOrderFilters();
        Object.keys(this.filterFormGroup!.controls).map((control)=>{
            let filter : {[key:string]:any} = {[control]: this.filterFormGroup?.controls[control].value}
            Object.assign(this.dataSource.filters, filter)
        })
        this.filterFormGroup!.disable();

        this.dataSource.reload();
        this.filterFormGroup!.enable();
        let filterClick = document.getElementById('filterPaneOrder');
        if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
            filterClick.setAttribute('filterClickAttached','true')
            filterClick.addEventListener("click",()=>{
                sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
            })
        }

    }

    getDivisionSrc(division: string){

        switch(division)
        {
            case 'Digital':
                return "/assets/images/FLNDivisionDigital.png"
            case 'Investigations':
                return "/assets/images/FLNDivisionInvestigations.png"
            case 'Records':
                return "/assets/images/FLNDivisionRecords.png"
            case 'Court & Process':
                return "/assets/images/FLNDivisionCourtProcess.png"
            case 'Depositions':
                return "/assets/images/FLNDivisionDepositions.png"
            case 'Discovery':
                return "/assets/images/FLNDivisionDiscovery.png"
        }
        return '';
    }

    setDivisionInfo(entity: Order){
        let entityType: ServiceType = new ServiceType();
        let entityCategory: ServiceCategory = new ServiceCategory();
        let entityService = this.filingServiceService.services.value.find((currentService)=>{
            let currentCategory = currentService.categories.find((category)=>{
                let type = category.types.find((type)=>{
                    return type.uuid == entity.filingServiceType_uuid;
                })
                if(type){
                    entityType = type;
                }
                return !!type;
            })
            if(currentCategory){
                entityCategory = currentCategory.category;
            }
            return !!currentCategory;
        })
        if(entityType.name && entityCategory.name){
            entity.divisionExtended = (entityCategory.parentCategory ? entityCategory.parentCategory : entityCategory.name) + ": " + entityType.displayName;
            return entity.divisionExtended;
        }
        entity.divisionExtended = ' ';
        return entity.divisionExtended;
    }

    getDateUTC(s: number, entity : any)
    {
        let o = new Order(entity);
        switch(s)
        {
            case 1:
                return o.orderDateUTC;
            case 2:
                return o.jobDateUTC;
            case 3:
                return o.dueDateUTC;
            case 4:
                return o.updatedAtUTC;
        }
        return null;
    }

    nonDatatrac(name: string){
        if(name == 'Digital' || name == 'Discovery' || name == 'Investigations'){
            return true;
        }
        return false;
    }

    allowSSO(entity: any) {
        return entity.division == 'Records';
    }

    SSORedirect() {
        if(!this.userPermissionService.loggedInUser)
            return;

        this.dialog.open(SelectVendorAccountDialog, {
            data: {
                vendor:this.vendorAccountService.vendorName.RecordsRetrieval,
                accounts: this.recordsRetrievalAccounts
            }
        }).afterClosed().subscribe((vendorAccount)=>{
			//redirect logic
            if(!vendorAccount)
                return;

            this.telemetryService.announce
            (
                TelemetryEventType.recordsSSO, 
                this.router.url, 
                this.userPermissionService.loggedInUser
            )
            .subscribe();

            window.open(environment.config['TristarLoginUrl'], "_blank");
        })
    }

    test(){
        console.debug("Sort:",this.sort);
        console.debug("table:", this.dataSource);
    }

    ngOnInit() {
        super.ngOnInit();
        if(this.sysConfigService.maintenanceRedirectCheck()){  
            window.localStorage.removeItem("loggedInUserPermissionNodes");
            this.auth.logoff();
        };
        if(this.sort){
            this.dataSource.sort = this.sort;
            this.dataSource.pageSize = 25;
        }
        this.dataSource.loading.subscribe((load)=>{
            this.loading = load;
        })
        this.filterFormGroup = this.fb.group(DEFAULT_SEARCH, {
            validator: [OrderList.createOrderDateRangeValidator]
        });

        // private filingTypeService: ServiceType_Service,
        // private filingCategoryService: ServiceCategory_Service,

        // this.dataSource.sortingDataAccessor = (order:Order, property:any) =>{
        //     type orderKey = keyof Order;
        //     let prop: orderKey = property;
        //     console.log("Property:", property, " With Order Prop:", prop);
        //     switch(property){
        //         case 'orderNumber':
        //             if(order.orderNumber) return order.orderNumber;
        //             else return order.orderId;
        //         case 'orderDate':
        //             if(order.orderDate) return order.orderDate
        //             else return order.orderDate
        //         default: return order[prop]
        //     }
        // }

        // // a < b -, a = b 0, a > b +
        // this.dataSource.sortData = (data, sort) => {
        //     return data.sort((a: Order, b: Order)=>{
        //         if(sort.active == 'orderNumber'){
        //             if(!a.orderNumber && !b.orderNumber) return 0;
        //             if(!a.orderNumber) return -1;
        //             if(!b.orderNumber) return 1;
        //             return a.orderNumber.charCodeAt(0) - b.orderNumber.charCodeAt(0);
        //         } else if (sort.active == 'orderDate'){
        //             return 1
        //         } else {
        //             let active = sort.active;
        //             return  0;
        //         }
        //     })
        // }

        this.auth.isAuthenticated$.subscribe((authenticated) => {
            if(!authenticated.isAuthenticated) return;

            this.userPermissionService.userSync.subscribe((userSynced) => {
                if(!userSynced || !this.userPermissionService.loggedInUser) return;
                let user = this.userPermissionService.loggedInUser;

                // gets Records Retrieval Account
                this.vendorAccountService.getVendorAccountsByUsernameAndVendor({username: user.username, vendorName: this.vendorAccountService.vendorName.TRISTAR})
                    .subscribe((result)=>{
						console.debug("siteFrame: recordsRetrievalAccounts:", this.recordsRetrievalAccounts);
                        this.recordsRetrievalAccounts = result;
                    });
            });
        });
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        setTimeout(()=>{
            if(!this.filterFormGroup){
                this.filterFormGroup = this.fb.group(DEFAULT_SEARCH, {
                    validator: [OrderList.createOrderDateRangeValidator]
                });
            }
            this.filterFormGroup.disable();
            this.auth.isAuthenticated$.subscribe((authenticated) => {
                if(!authenticated) return;
    
                this.userPermissionService.userSync.subscribe((userSynced: boolean) => {
    
                    if(!userSynced) return;
                    if(this.userPermissionService.canDo(SuccessAccountPermissionNodeName.view_orders_for_firm_accounts)) {
                        this.standardFilters = {
                            corpId: this.userPermissionService.loggedInUser?.corpId,
                            byCorp: true
                        };
                        this.filterFormGroup?.controls['corpId'].setValue(this.userPermissionService.loggedInUser?.corpId)
                    } else {
                        this.standardFilters = {
                            placedByEmail: this.userPermissionService.loggedInUser?.email,
                            byUser: true
                        };
                        this.filterFormGroup?.controls['placedByEmail'].setValue(this.userPermissionService.loggedInUser?.email)
                    }
    
                    // this.orderFilters = this.standardFilters;
                    let filterClick = document.getElementById('filterPaneOrder');
                    if(filterClick && !filterClick.hasAttribute('filterClickAttached')){
                        filterClick.setAttribute('filterClickAttached','true')
                        filterClick.addEventListener("click",()=>{
                            sessionStorage.setItem("filterCollapsed", this.filterPane.isCollapsed ? "true" : "false");
                        })
                    }
    
                    this.search();
                })
                
            });
        })
    }
}
