


<ng-container>
    <div [style.margin-left]="(index) * 25 + 'px'" class="row" (click)="folderData ? expandFolder() : null">
        <div class="col" class="folder">
            <mat-icon>{{folderData?.expanded ? "arrow_drop_down" : "arrow_right"}}</mat-icon> {{folderData?.folderName}}
        </div>
    </div>

    <ng-container *ngIf="subFolders && subFolders.length && (index == 0 || folderData?.expanded)">
        <recursive-folder *ngFor="let folder of subFolders" 
                          [folderData]="folder"
                          [index]="index + 1"
                          [documentSelection$]="documentSelection$" 
                          [drillDown]="curDrilldown"
                        >
        </recursive-folder>
    </ng-container>
    
    <ng-container *ngIf="documents && documents.length && folderData?.expanded">
        <div [style.margin-left]="((index + 1) * 25) + 'px'">
            <mat-chip-list class="mat-chip-list-stacked" [multiple]="true" *ngFor="let document of documents">
                <!-- <mat-chip [disabled]="!fileTypeMap.get(document.documentType.trim().toLowerCase())"  -->
                <mat-chip [disabled]="!validFileTypes.indexOf(document.extension.toLowerCase())" 
                        (click)="selectDocument(document.id)" 
                        [selected]="document.selected"
                >
                            {{ document.fileName ? document.fileName : " -- unknown/invalid file type" }} ({{sizeInMb(document.size)}})
                </mat-chip>
            </mat-chip-list>
        </div>
    </ng-container>
    <ng-container *ngIf="loading">
        <b [style.margin-left]="((index + 1) * 25) + 'px'">Loading Folder Content. . .</b>
    </ng-container>
    <ng-container *ngIf="!loading && (!subFolders || (subFolders && !subFolders.length)) && (!documents || (documents && !documents.length)) && folderData?.expanded">
        <div [style.margin-left]="((index + 1) * 25) + 'px'"><b>Empty / No Importable Files</b></div>
    </ng-container>

</ng-container>