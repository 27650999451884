

<div style="top:30%; left: 50%; width:550px; height: 600px;">
    <div *ngIf="noBindings" style="width:100%; height:100%">
        <div style="width:100%; height:90%;">
            <h1 mat-dialog-title>Bind iManage Account</h1>
            <br/>
            <div>Please bind your iManage account to import your iManage files.</div>
            <br/>
            <div>This can be done by selecting "Send to First Legal" and confirming the user binding.</div>
            <br/>
            <div style="display: flex; justify-content: center;">
                <a href="https://cloudimanage.com" target="_blank" style="font-size:25px"><b>Open iManage</b></a>
            </div>
            <br/>
            <br/>
            <div>After you've bound your iManage account, please click the 'Refresh' button to retry the import.</div>
            <br/>
        </div>
        <div mat-dialog-actions style="width:100%; height:10%">
            <button mat-raised-button mat-dialog-close (click)="cancelDownload()">Cancel</button>
            <button mat-raised-button (click)="refresh()">Refresh</button>
        </div>
    </div>
    <div *ngIf="!importing && !noBindings" style="width:100%; height:100%">
        <h1 mat-dialog-title>Import Document(s) From iManage</h1>
        <div class="directory">
            <b *ngIf="loading" style="position:relative; top:10px; left:10px">Loading. . .</b>
            <b *ngIf="!loading && !libraries.length" style="position:relative; top:10px; left:10px">No Accessible Libraries/Databases.</b>
            <table>
                <tr *ngFor="let library of libraries">
                <td>
                    <div class="folder" (click)="ExpandLibrary(library.libraryName)">
                        <mat-icon>{{library.expanded ? "keyboard_double_arrow_down" : "keyboard_double_arrow_right"}}</mat-icon> <b>{{library.libraryName}} [Library]</b>
                    </div>
                    <table *ngIf="library.expanded">
                        <tr *ngFor="let workspace of workspaceMap.get(library.libraryName.toLowerCase())">
                            <td>
                                <div class="folder" [style.margin-left]="'15px'" (click)="ExpandWorkspace(library.libraryName, workspace.workspaceId)">
                                    <mat-icon>{{workspace?.expanded ? "keyboard_arrow_down" : "keyboard_arrow_right"}}</mat-icon> {{workspace.workspaceName}} [Workspace] 
                                </div>
                                <table *ngIf="workspace.expanded">
                                    <recursive-folder *ngFor="let folder of folderMap.get(workspace.workspaceId)" 
                                        [folderData]="folder" 
                                        [index]="index + 1" 
                                        [documentSelection$]="documentSelection"
                                        [drillDown]="drilldown"
                                    >
                                    </recursive-folder>
                                    <div *ngIf="!workspace?.hasSubfolders" [style.margin-left]="'50px'"><b>Empty / No Importable Files</b></div>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
                </tr>
            </table>
        </div>

        <div style="width: 100%; text-align:center; padding-top:5px">Only files from the same folder may be imported at once. The import may be ran multiple times.</div>
        <div mat-dialog-actions style="bottom:0%; left: 50%;">
            <button mat-raised-button mat-dialog-close (click)="close()">Close</button>
            <div matTooltip="{{nextTooltip}}" [matTooltipDisabled]="validateNext()">
                <button mat-raised-button class="nextButton" (click)="import()" [disabled]="!validateNext()">{{importButtonText}}</button>
            </div>
        </div>
    </div>
    <div *ngIf="importing && !noBindings" style="width:100%; height:100%">
        <h1 mat-dialog-title>Importing Document(s) From iManage</h1>
        <div class="directory">
            <table>
                <tr *ngFor="let document of selectedDocumentsList">
                    {{document.fileName}}
                    <!-- {{document.docName  + "." + (document.docType.toLowerCase() == "word" ? "doc" : document.docType.toLowerCase() == "wordx" ? "docx" : "pdf")}}  -->
                    <mat-icon>{{document.downloaded == 0 ? "more_horiz" : document.downloaded == 1 ? "downloading" : document.downloaded == 2 ? "check" : "error"}}</mat-icon>
                    {{document.downloaded == 1 ? "Downloading. . ." : document.downloaded > 2 ? "Unable to retrieve file." : ""}}
                </tr>
            </table>
        </div>

        <div style="width: 100%; text-align:center; padding-top:5px">The order may load while waiting for documents to import.</div>
        <div mat-dialog-actions style="bottom:0%; left: 50%;">
            <div matTooltip="Import Complete." [matTooltipDisabled]="!importComplete">
                <button mat-raised-button mat-dialog-close [disabled]="importComplete" (click)="cancelDownload()">Cancel</button>
            </div>
            <div matTooltip="Import in progress. Please wait." [matTooltipDisabled]="importComplete">
                <button mat-raised-button class="nextButton" (click)="finishImport()" [disabled]="!importComplete">Finish</button>
            </div>
        </div>
    </div>
</div>