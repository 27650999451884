import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatListOption } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessAccount_Service, UserPermission_Service, SuccessAccountSettingsService } from '../../services';
import { Day, ContactTrigger, ContactFrequency, ContactPreference, SuccessAccountSettings, SuccessAccount } from '../../models';
import { switchMap, tap } from 'rxjs/operators';
import { CommonData_Service } from '../../services';
import { LookupConfiguration } from '../lookup/configurations';
import { BehaviorSubject } from 'rxjs';

const { convertMinutesToTimeString, convertTimeStringToMinutes } = SuccessAccountSettings;

const notEmpty = (control: AbstractControl): ValidationErrors | null => {
  let list = control.value as any[];
  return list.length < 1 ? { empty: true } : null;
}

@Component({
  selector: 'lib-notification-preferences',
  templateUrl: './notification-preferences.component.html',
  styleUrls: ['./notification-preferences.component.scss']
})



export class NotificationPreferencesComponent implements OnInit {

  @Input()
  public user: SuccessAccount | undefined;
  @Input()
  public settings: SuccessAccountSettings = new SuccessAccountSettings();
  public val: string ='';
  @Output()
  public onSave = new EventEmitter<SuccessAccountSettings>();

  Day = Day;
  ContactPreference = ContactPreference;
  ContactFrequency = ContactFrequency;
  ContactTrigger = ContactTrigger;

  @Input()
  public parent: FormGroup | undefined;
  form: FormGroup | undefined;

  // need refs here, since settings doesn't store the arrays for these.
  // need to remember to set these in the save method
  ccOptions: SuccessAccount[] = [];
  ccStrings: String[] = [];
  notificationCCs: SuccessAccount[] = [];

  notificationStrings: string[] = [];
  accountLookupConfig: LookupConfiguration<SuccessAccount>;

  public successAccountSettings$: BehaviorSubject<SuccessAccountSettings[]> = new BehaviorSubject<SuccessAccountSettings[]>([]);
  public successAccounts$: BehaviorSubject<SuccessAccount[]> = new BehaviorSubject<SuccessAccount[]>([]);

  get scheduledTimes() {
    return Object.values(this.scheduledTimeGroup.controls) as FormControl[];
  }

  get invalid() {
    return this.form?.invalid;
  }

  get zones() {
    return this.commonDataService.timezones;
  }

  get currentZone() {
    let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.zones.find(tz => tz.value == zone);
  }

  constructor(
    // indirectly call syncUser by injecting the service, lol
    private userPermissionservice: UserPermission_Service,
    private successAccountService: SuccessAccount_Service,
    private successAccountSettingsService: SuccessAccountSettingsService,
    private commonDataService: CommonData_Service,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    this.accountLookupConfig = successAccountService.makeLookupConfigEmail();
  }
  
  prettyZone(code: string) {
    if (!code)
      return code;
    return code.replace(/_/g, ' ');
  }

  public fetch_SuccessAccountSettings(entity: SuccessAccount){
    if(!entity || (entity && !entity.uuid)){
      this.successAccountSettings$.next([]);
      return;
    }
  
    this
        .successAccountSettingsService
        .list({
          reloadFresh: true,
          where: {
            includeDeleted:true,
            filters: {
                successAccount_uuid: {
                    value: entity.uuid,
                    operator: "="
                },
                    
            }
        }
        })
        .subscribe(
          (items) => {
              this.successAccountSettings$.next(items);
          }
      )
  
  }
  


  ngOnInit(): void {
    let settings = this.settings;
    // set defaults from model
    let g = this.fb.group({
      contactPreference: [settings.contactPreference],
      contactTrigger: [settings.contactTriggerOption],
      contactFrequency: [settings.contactFrequencyOption],
      notificationScheduleDays: [settings.notificationScheduleDays],
      pauseNotificationsToDate: [settings.pauseNotificationsToDate],
      pauseNotificationsToTime: [],
      scheduledTimes: this.fb.group({}),
      notificationCCs: this.fb.group({}),
      selectedCC: [],
      timeZone: [settings.timezoneCode],
      hasNoContactHours: [settings.hasNoContactHours],
      noContactFromTime: [],
      noContactToTime: []
    });
    if (this.parent) {
      this.form = this.parent;
      for (let controlName in g.controls) {
        this.form?.addControl(controlName, g.controls[controlName]);
      }
    }
    else {
      this.form = g;
    }
    
    // watch contactFrequency
    this.form?.get('contactFrequency')?.valueChanges.subscribe((frequency: ContactFrequency) => {
      if (frequency == ContactFrequency.once_daily) {
        let vals = this.scheduledTimes;
        for (let i = 1; i < vals.length; i++) {
          this.removeScheduleTime(vals[i]);
        }
        if (this.scheduledTimes.length == 0)
          this.addScheduledTime();
      }
    });
    let notificationScheduleDays = this.form?.get('notificationScheduleDays');
    this.form?.get('contactTrigger')?.valueChanges.subscribe((trigger: ContactTrigger) => {
      if (trigger == ContactTrigger.schedule) {
        notificationScheduleDays?.setValidators([notEmpty]);
      }
      else {
        notificationScheduleDays?.clearValidators();
      }
      notificationScheduleDays?.updateValueAndValidity();
    })

    this.initForm();
    this.mapCCsString();
  }

  async initForm() {
  
    if (!this.user || !this.form) 
      return;
    let settings = this.settings;
    console.debug('loaded user settings', this.settings);

    // need to lookup success accounts for these
    // settings.notificationCCs
    // this.notificationCCs = settings.notificationCCs;
    let user: SuccessAccount | null = null;
    this.successAccountService.user.pipe(
      tap(u => {
        user = u;
      }),
      switchMap(user => {
        if(!user || user.corpId == '') return []
        
        return this.successAccountService.getSuccessAccountsByCorpId(user.corpId);
      })
    ).subscribe(ccs => {
      let selected = settings.notificationCCs;
      this.ccOptions = ccs.filter(cc => cc.uuid != user?.uuid && !selected.includes(cc.uuid));
      this.notificationCCs = ccs.filter(cc => selected.includes(cc.uuid));
    });

    for (let time of this.settings.scheduledTimes) {
      let value = convertMinutesToTimeString(time);
      this.scheduledTimeGroup.addControl(`${this._id++}`, new FormControl(value));
    }
    this.form.get('contactPreference')?.setValue(settings.contactPreference);
    this.form.get('contactTrigger')?.setValue(settings.contactTriggerOption);
    this.form.get('contactFrequency')?.setValue(settings.contactFrequencyOption);
    this.form.get('notificationScheduleDays')?.setValue(settings.notificationScheduleDays);
    this.form.get('pauseNotificationsToDate')?.setValue(settings.pauseNotificationsToDate);
    
    this.form.get('hasNoContactHours')?.setValue(settings.hasNoContactHours);
    this.form.get('noContactFromTime')?.setValue(convertMinutesToTimeString(settings.noContactFromTime))
    this.form.get('noContactToTime')?.setValue(convertMinutesToTimeString(settings.noContactToTime))
    
    if (settings.pauseNotificationsToDate) {
      let hours = settings.pauseNotificationsToDate.getHours();
      let hh = hours < 10 ? '0' + hours : hours;
      let minutes = settings.pauseNotificationsToDate.getMinutes();
      let mm = minutes < 10 ? '0' + minutes : minutes;
      this.form.get('pauseNotificationsToTime')?.setValue(`${hh}:${mm}`)
    }
  }

  get hasNoContactHours() {
    return this.form?.get('hasNoContactHours');
  }

  get scheduledTimeGroup() {
    return this.form?.get('scheduledTimes') as FormGroup;
  }

  get contactTrigger() {
    return this.form?.get('contactTrigger');
  }

  get contactFrequency() {
    return this.form?.get('contactFrequency');
  }

  get scheduleTimeLimitReached() {
    let limit = SuccessAccountSettings.MAX_SCHEDULE_TIMES;
    if (this.contactFrequency?.value == ContactFrequency.once_daily) {
      limit = 1;
    }
    return limit <= this.scheduledTimes.length;
  }

  get numberOfCCsLimitReached() {
    return SuccessAccountSettings.MAX_NOTIFICATION_CCS == this.notificationStrings.length;
  }

  _id = 1;

  addScheduledTime(e: Event | undefined = undefined) {
    if (e)
      e.preventDefault();
    if (this.scheduleTimeLimitReached) {
      return;
    }
    let control = new FormControl('12:00');
    control.setValidators([Validators.required]);
    this.scheduledTimeGroup.addControl(`key_${this._id++}`, control);
    this.scheduledTimes.push(control);
  }

  removeScheduleTime(time: FormControl, e: Event | undefined = undefined ) {
    if (e)
      e.preventDefault();
    console.log(time);
    // really angular?
    //this.scheduledTimeGroup.removeControl(time);
    for (let key in this.scheduledTimeGroup.controls) {
      let other = this.scheduledTimeGroup.controls[key];
      if (other === time) {
        this.scheduledTimeGroup.removeControl(key);
        break;
      }
    }
  }

  validateEmail(mail : string){
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
      
      return true;
    }
      return false;
  }

  addNotificationCC(val : string){

    if(this.notificationStrings.length>=SuccessAccountSettings.MAX_NOTIFICATION_CCS){
        return;
    }

    this.notificationStrings.push(val);
  }

  checkAndAdd(val:string){
    if(this.validateEmail(val)){
      this.addNotificationCC(val)
    }
    else
    return;
  }


  removeCC(cc: String){
    this.notificationStrings = this.notificationStrings.filter(email => email != cc)
    this.ccStrings.push(cc)
  }



  mapCCsString(){
    
    for(let i=0; i<this.settings.notificationCCs.length; i++){
        let email = this.settings.notificationCCs[i]
        this.notificationStrings[i] = email;
    }
  }

  bindFormValuesToSettings() {
    console.log('form', this.form);
    if (!this.form) 
      return;
    let settings = this.settings;

    // notifications
    settings.scheduledTimes = this.scheduledTimes.map(time => {
      return convertTimeStringToMinutes(time.value);
    }) as number[];
    //settings.notificationCCs = this.notificationCCs.map(account => account.uuid);
    settings.notificationCCs = this.notificationStrings;

    settings.contactPreference = this.form.get('contactPreference')?.value;
    settings.contactTriggerOption = this.form.get('contactTrigger')?.value;
    settings.contactFrequencyOption = this.form.get('contactFrequency')?.value;
    settings.notificationScheduleDays = this.form.get('notificationScheduleDays')?.value;
    settings.pauseNotificationsToDate = this.form.get('pauseNotificationsToDate')?.value;

    settings.hasNoContactHours = this.form.get('hasNoContactHours')?.value;
    if (!settings.hasNoContactHours) {
        settings.noContactFromTime = null;
        settings.noContactToTime = null;
    } else {
        settings.noContactFromTime = convertTimeStringToMinutes(this.form.get('noContactFromTime')?.value);
        settings.noContactToTime = convertTimeStringToMinutes(this.form.get('noContactToTime')?.value);  
    }

    this.val = this.form.get('selectedCC')?.value;
    if (!this.form.get('timeZone')?.value && this.currentZone) {
      this.form.get('timeZone')?.setValue(this.currentZone.value);
    }
    settings.timezoneCode = this.form.get('timeZone')?.value;

    if (settings.pauseNotificationsToDate) {
      let toTime = this.form.get('pauseNotificationsToTime')?.value;
      if (toTime) {
        let [hours, minutes] = toTime.split(':').map((d: string) => Number.parseInt(d));
        settings.pauseNotificationsToDate.setHours(hours, minutes);
      }
    }



    if (settings.contactTriggerOption == ContactTrigger.immediate) {
      // clear any schedule options
      settings.contactFrequencyOption = ContactFrequency.once_daily;
      settings.notificationScheduleDays = [];
      settings.pauseNotificationsToDate = null;
      settings.scheduledTimes = [];
      for (let key in this.scheduledTimeGroup.controls)
        this.scheduledTimeGroup.removeControl(key);
    }

    // noContactFromTime: [],
    // noContactToTime: []
    // console.debug('settings', settings);
    // this.successAccountService.saveSettings(settings)
    //   .subscribe({
    //     next: () => {
    //       this.onSave.emit(settings);
    //       this.snackBar.open('Preferences Saved', undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
    //     },
    //     error: err => {
    //       let message = err.error?.error || 'Something went wrong saving the preferences';
    //       this.snackBar.open(message, undefined, { duration: 4000, horizontalPosition: 'left', panelClass: 'snack-error' })
    //       console.error(err);
    //     }
    //   });
  }

  active(option: MatListOption) {
    return option.selected ? 'active' : '';
  }

}
