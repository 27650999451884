import { Field } from '@smartsoftware/reflex-core';
import { DexResourceModel } from '../abstracts/dexResource.model';

export class ClientBranch extends DexResourceModel {

	@Field(String)
    public clientId: string = "";

	@Field(String)
	public name: string = "";

	@Field(String)
	public default_clientMatterSet_uuid: string | null = null;

	@Field(String)
	public insuranceCompany: string | null = null;
	
	@Field(String)
	public insuranceAdjuster: string | null = null;

	@Field(Number)
	public customerNumber: number = 0; 

    @Field(String)
    public errorMessage: string | null = null;

    @Field(String)
    public companyNumber: number = 0;
	
	@Field(Number)
	public masterCustomerNumber: number | null = null;
	
	@Field(Number)
	public masterCompanyNumber: number | null = null;
	
	constructor(data?: any) {
		super();
		this.mapData(data);
	}
}
