


import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { VendorAccount_Service, Vendor_Service, UserPermission_Service } from 'legalreflex-lib';
import { BehaviorSubject } from 'rxjs';

const iManage: string = "iManage";

@Component({
    templateUrl: 'importDocument.html',
    styleUrls: ['./importDocument.scss']
})
export class ImportDocumentDialog {

    public importedFiles: BehaviorSubject<any>;
    public documentSelection: BehaviorSubject<any> = new BehaviorSubject(null);

    public workspaceMap: Map<String, WorkspaceRow[]> = new Map();
    public folderMap: Map<String, FolderRow[]> = new Map();
    
    public libraries: LibraryRow[] = [];
    public selectedDocumentsList: DocumentDetails[] = [];
    public drilldown: string[] = [];
    
    public importButtonText: string = "";
    public successAccountUUID: string = "";
    public nextTooltip: string = "";
    
    public index: number = 0;
    public fileSize: number = 0;
    public fileSizeLimit: number = 24576000; 
    
    public importing: boolean = false;
    public importComplete: boolean = false;
    public cancelled: boolean = false;
    public loading: boolean = true;
    public noBindings: boolean = false;

constructor(
    @Inject(MAT_DIALOG_DATA) public data: {successAccountUUID: string, noBindings: boolean, importedFiles: BehaviorSubject<any>, fileSize: number},
    private dialogRef : MatDialogRef<ImportDocumentDialog>,
    protected vendorAccountService: VendorAccount_Service,
    protected vendorService: Vendor_Service,
    protected userPermissionService: UserPermission_Service
    ) {
        console.log("importDocuments:", data);
        this.successAccountUUID = data.successAccountUUID;
        this.importedFiles = data.importedFiles;
        this.noBindings = data.noBindings;
        this.fileSize = data.fileSize;
        this.importButtonText = `No Files Selected`;
        let drilldown = localStorage.getItem("folderDrilldown");
        if(typeof drilldown == "string"){
            this.drilldown = drilldown.split(",");
        }
    }

    ngOnInit(): void {
        if(!this.noBindings)
            this.loadData();

        this.documentSelection.subscribe((docSelected: DocumentDetails) => {
            if(docSelected){
                docSelected.downloaded = 0;
                if(!docSelected.selected){
                    this.fileSize -= Number(docSelected.size);
                    this.selectedDocumentsList = this.selectedDocumentsList.filter(doc => doc.id != docSelected.id);
                } else {
                    this.fileSize += Number(docSelected.size);
                    this.selectedDocumentsList = this.selectedDocumentsList.filter(doc => doc.workspace_id == docSelected.workspace_id);
                    this.selectedDocumentsList.push(docSelected);
                }
                if(this.selectedDocumentsList.length){
                    this.importButtonText = `Import ${this.selectedDocumentsList.length} File${this.selectedDocumentsList.length > 1 ? 's' : ''}`;
                } else {
                    this.importButtonText = "No Files Selected";
                }
            }
        });
    }

    ExpandLibrary(libraryName: string){
        this.libraries.forEach((libRow: LibraryRow) => {
            if(libRow.libraryName.toLowerCase == libraryName.toLowerCase){
                libRow.expanded = !libRow.expanded;
                if(libRow.expanded)
                    this.drilldown.push(libraryName);
                else
                    this.drilldown = this.drilldown.filter(a => a != libraryName);
                this.saveDrilldown();
            }
        })
    }
    
    ExpandWorkspace(libraryName: string, workspaceId: string, skipDrilldown: boolean = false){
        this.workspaceMap.get(libraryName.toLowerCase())?.forEach((workRow: WorkspaceRow) =>{
            if(workRow.workspaceId == workspaceId){
                workRow.expanded = !workRow.expanded;
                if(!skipDrilldown){
                    if(workRow.expanded)
                        this.drilldown = [libraryName, workspaceId];
                    else
                        this.drilldown = this.drilldown.filter(a => a != workRow.workspaceId);
                    this.saveDrilldown();
                }
                if(workRow.expanded){
                    this.vendorAccountService.getiManageWorkspaceContents(workRow.authToken, workRow.customerId, workRow.libraryId, workRow.workspaceId).subscribe(ret => {
                        if(ret){
                            this.folderMap.delete(workspaceId);
                            ret.result.data.forEach((folderData: any) => {
                                if(!this.folderMap.get(workspaceId)){
                                    this.folderMap.set(workspaceId, [{authToken: workRow.authToken, customerId: workRow.customerId, libraryId: workRow.libraryId, folderName: folderData.name, folderId: folderData.id, expanded: false}]);
                                } else {
                                    this.folderMap.get(workspaceId)?.push({authToken: workRow.authToken, customerId: workRow.customerId, libraryId: workRow.libraryId, folderName: folderData.name, folderId: folderData.id, expanded: false});
                                }
                            });
                        }
                    });
                }
            }
        });
    }

    close(){
        this.dialogRef.close(false);
    }

    cancelDownload(){
        this.cancelled = true;
        this.dialogRef.close(false);
    }

    import(){
        this.dialogRef.disableClose = true;
        this.importing = true;

        this.dialogRef.disableClose = true;
        this.importing = true;
  
        let place = 0;
        this.selectedDocumentsList[place].downloaded++
        this.selectedDocumentsList.forEach((d: DocumentDetails) => {
            console.log("Doc:", d);
            console.log("wm:", this.workspaceMap);
            this.importedFiles.next({
                auth_token: d.auth_token,
                refresh_token: d.refresh_token,
                vendorId: d.id,
                name: d.name,
                fileName: d.fileName,
                extension: d.extension,
                sequenceIndex: 0,
                vendorDownloadURI: 'https://cloudimanage.com/work/web/api/v2/customers/' + d.customerId + '/libraries/' + d.libraryId + '/documents/' + d.id + '/download'
            })
            this.selectedDocumentsList[place++].downloaded++;
            if(this.selectedDocumentsList[place])
                this.selectedDocumentsList[place].downloaded++;
        })

        this.importComplete = true;
    }

    public loadData(){
        console.log("loadData:", this.successAccountUUID);
        if(this.successAccountUUID?.trim().length){
            this.vendorAccountService.getiManageAuthTokens(this.successAccountUUID.trim()).subscribe((res: {email: string, authToken: string}[]) => {
                if(res){
                    let authTokens: string[] = [];
                    let emails: string[] = [];
                    console.log("res:", res);
                    res.forEach(r => {
                        authTokens.push(r.authToken);
                        emails.push(r.email);
                    })
                    this.vendorAccountService.getiManageDirectoryInformation(authTokens).subscribe((data: CustomerData[]) => {
                        if(data && data.length){
                            let dataIdx = 0;
                            data.forEach((customerInfo: CustomerData) => {
                                customerInfo.libraries.libraryIds.forEach((lid: string) => {
                                    this.libraries.push({libraryName: lid, expanded: this.drilldown.includes(lid) ? true : false});
                                });
                                this.loading = false;
                                let curAuthToken: string = authTokens[dataIdx];
                                let curEmail: string = emails[dataIdx++];
                                this.vendorAccountService.getiManageUserId(curAuthToken, customerInfo.customerId.customerId, curEmail).subscribe((userId: any) => {
                                    customerInfo.libraries.libraryIds.forEach((libraryId: string) => {
                                        this.vendorAccountService.getiManageWorkspaces(curAuthToken, customerInfo.customerId.customerId, libraryId, userId.message).subscribe((workspaces: any) => {
                                            if(workspaces){
                                                workspaces.data.results.forEach((workspace: any) => {
                                                    if(!this.workspaceMap.get(workspace.database.toLowerCase())){
                                                        this.workspaceMap.set(workspace.database.toLowerCase(), [{authToken: curAuthToken, customerId: customerInfo.customerId.customerId, libraryId: libraryId,  workspaceName: workspace.name, workspaceId: workspace.id, hasSubfolders:workspace.has_subfolders, expanded: false}]);
                                                    } else {
                                                        this.workspaceMap.get(workspace.database.toLowerCase())?.push({authToken: curAuthToken, customerId: customerInfo.customerId.customerId, libraryId: libraryId,  workspaceName: workspace.name, workspaceId: workspace.id, hasSubfolders:workspace.has_subfolders, expanded: false});
                                                    }
                                                    if(this.drilldown.includes(workspace.id))
                                                        this.ExpandWorkspace(libraryId, workspace.id, true);
                                                });
                                            }
                                        });
                                    });
                                })
                            });
                        } else {
                            console.log("Didn't find any customerId's or libraries bound to this success/vendor account.");
                        }
                    });
                } else {
                    console.log("Didn't find an auth token for the success account.");
                }
            });
        } else {
            console.log("No successAccountUUID provided.");
        }
    }

    public validateNext(){
        // console.log("validateNext:", this.selectedDocumentsList.length, ":l|fs:", this.fileSize, "|", this.fileSizeLimit);
        if(!this.selectedDocumentsList.length){
            this.nextTooltip = "Please select at least 1 file to import.";
            this.importButtonText = "No Files Selected";
            return false;
        }
        if(this.fileSize > this.fileSizeLimit){
            this.nextTooltip = "The file(s) selected exceed the maximum (24mb) total file size."
            this.importButtonText = "File Size Limit Exceeded"
            return false;
        }
        return true;
    }

    public saveDrilldown(){
        let drilldown: string = "";
        this.drilldown.forEach( a => {
            drilldown += a + ",";
        });
        localStorage.setItem("folderDrilldown", drilldown);
    }

    public refresh(){
        this.vendorAccountService.getVendorAccountsByUsernameAndVendor({ 
            username: this.userPermissionService.loggedInUser!.username, vendorName: iManage
        }).subscribe(res => {
            this.noBindings = res.length < 1;
            if(!this.noBindings){
                this.loadData();
            }
        })  

    }

    finishImport(){
        this.dialogRef.close(true);
    }
}



interface CustomerData {
    customerId: {customerId: string},
    libraries: {libraryIds: string[]}
}

interface LibraryRow {
    libraryName: string,
    expanded: boolean
}

interface WorkspaceRow {
    authToken: string,
    customerId: string,
    libraryId: string,
    workspaceName: string,
    workspaceId: string,
    hasSubfolders: boolean,
    expanded: boolean
}

interface FolderRow{
    authToken: string, 
    customerId: string, 
    libraryId: string,
    folderName: string,
    folderId: string, 
    expanded: boolean
}

interface DocumentDetails {
    auth_token: string, 
    refresh_token: string,
    id: string,
    name: string,
    extension: string,
    workspace_id: string,
    workspace_name: string,
    fileName: string,
    size: string,
    customerId: string,
    libraryId: string, 
    selected: boolean,
    downloaded: number
}

