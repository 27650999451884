import { Service, ServiceCategory, ServiceType } from "../models"

export interface UserData {
    customerId?: number,
    email?: string,
    id?: string,
    name?: string
}

export interface CaseData {
    uuid?: string,
    vendorId?: string,
    name: string,
    caseNumber: string,
    frequentCallerId?: string
    corpId: string,
    // clientMatterNumber: string,
    successAccount_uuid: string
    plaintiff?: string,
    defendant?: string
}

export interface DocumentData {
    auth_token?: string,
    refresh_token?: string,
    vendor_id?: string,
    name?: string,
    fileName?: string,
    extension?: string,
    sequenceIndex?: number,
    vendorDownloadURI?: string,
    documentReturnURI?: string
}

export interface SubmitDocument {
    uuid: string,
    vendorId: string,
    documentType: string,
    sourceDocument_uuid?: string,
    name: string,
    fileName: string,
    sequenceIndex: number,
    downloadUri?: string,
    uploadUri?: string,
    fileType: string
}

export interface CourtData {
    name: string,
    address: string,
    city: string,
    state: string,
    zip: string
}

export interface OrderServiceAddress {
    uuid?: string,
    name: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    attention?: string,
    room?: string,
    phone?: string,
    phoneExt?: number | null,
    serve?: ToServeData
}

export interface CustomerAccountInfo {
    system?: string,
    corpId?: string,
    companyNo?: number,
    customerNo?: string,
    name?: string,
    attention?: string,
    companyName?: string,
    street?: string,
    room?: string,
    city?: string,
    state?: string,
    zip?: string,
    country?: string,
    phone?: string,
    phoneExt?: string,
    accountNumber?: string,
    insuranceCompany?: string
    insuranceAdjuster?: string
}

export interface OrderDataJSON {
    isNewUserInfo: boolean,
    customerInformation: CustomerAccountInfo,
    serviceCategoryDescription: string,
    // TODO: fill in proper types for these as we create them
    depositionsServicesNeeded: any,
    depositionsFields: any,
    digitalFields: any,
    eDiscoveryServicesNeeded: any,
    trialServicesServicesNeeded: any,
    eDiscoveryFields: any,
    investigationFields: any,
    recordRetrievalFields: any
}

export interface OrderData {
    // bearer_Token: string,
	authToken?: string,
    refresh_token?: string,
    claimNumber?: string,
    insuranceCompany?: string,
    insuranceAdjuster?: string,
    clientMatterNumber: string,
    user: UserData,
    sourceVendor: string,
    vendor_submission_id?: string,
    case?: CaseData,
    filingServiceType_uuid?: string,
    documents: DocumentData[],
    requestNotes: string,
    court: CourtData,
    contacts?: ContactData[],
    attorneys?: ContactData[],
    plaintiffs?: string[],
    defendants?: string[],
    advancedFees: boolean,
    alwaysDeliverCourtesyFeesBack: boolean,
    hasNoContactHours: boolean,
    noContactFromTime: number | null,
    noContactToTime: number | null,
    firstAppearanceFees: boolean,
    customerNumber: string,
    companyNumber: string,
    addresses: OrderServiceAddress[],
    pickupAddress: OrderServiceAddress,
    orderData: string,
    successAccount_uuid?: string, 
    documentsDescription: string,
    vendor_key?: string,
    saveAsDraft?: boolean,
    orderId?: string
}

export interface ToServeData {
    uuid?: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    age: string,
    gender: string,
    ethnicity: string,
    weight: string,
    height: string,
    hair: string,
    eyes: string,
    relationship: string,
}

export interface ContactData {
    firstName?: string,
    lastName?: string,
    email: string,
    phone: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    isOrganization: boolean,
    barId?: string,
    companyName: string
}

export interface Defendant {
    name: string
}

export interface Plaintiff {
    name: string
}

export interface UpdateOrderData {
    clientMatterNumber?: string,
    insuranceCompany?: string,
    insuranceAdjuster?: string,
    advancedFees?: boolean,
    firstAppearanceFees?: boolean,
    customerNumber?: string,
    companyNumber?: string,
    claimNumber?: string,
    alwaysDeliverCourtesyFeesBack?: boolean
    hasNoContactHours?: boolean,
    noContactFromTime?: number,
    noContactToTime?: number,
    orderData?: string,
    documentsDescription?: string,
    filingServiceType_uuid?: string,
    addresses?: OrderServiceAddress[],
    pickupAddress?: OrderServiceAddress,
    requestNotes?: string,
    court?: CourtData,
    case?: CaseData,
    vendor_siteURL?: string,
    vendor_returnDocumentPath?: string
    plaintiffs?: string[],
    defendants?: string[]
}

export interface AddDocumentData {
    documents: Array<DocumentData>,
    sourceVendor: string
}

export interface RemoveDocumentData {
    documents: Array<string>
}

export interface ServiceCategoryWithTypes {
    category: ServiceCategory,
    subCategories?: ServiceCategory[],
    types: ServiceType[]
}

export interface ServiceWithCategories {
    service: Service,
    categories: ServiceCategoryWithTypes[]
}