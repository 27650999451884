


import {Component, Inject, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VendorAccount_Service } from 'legalreflex-lib';
@Component({
    selector: 'recursive-folder',
    templateUrl: './recursiveFolder.html',
    styleUrls: ['./importDocument.scss']
})
export class RecursiveFolder {
    @Input() documentSelection$: BehaviorSubject<any> | null = null;
    @Input() folderData: FolderRow | null = null;
    @Input() index: number = 0;
    @Input() drillDown: string[]  = [];

    public subFolders: FolderRow[] = [];
    public documents: DocumentDetails[] = [];
    public validFileTypes: string[] = [".pdf", "doc", "docx"];
    public curDrilldown: string[] = [];
    public loading: boolean = false;

    constructor(
        protected vendorAccountService: VendorAccount_Service
    ){ if(this.folderData?.expanded){this.loading = true}}

    ngOnInit(): void {
        this.curDrilldown = [...this.drillDown];
        if(this.folderData && this.curDrilldown.includes(this.folderData.folderId)){
            this.expandFolder(true);
        }

        this.documentSelection$?.subscribe((docSelected: DocumentDetails) => {
            if(docSelected){
                if(!docSelected.selected && docSelected.libraryId != this.folderData?.libraryId){
                    this.documents.forEach((doc: DocumentDetails) => {
                        doc.selected = false;
                    });
                }
            }
        });
    }
    
    public expandFolder(skipDrilldown: boolean = false){
        if(!this.folderData)
            return;
        if(!this.folderData.expanded){
            this.loading = true;
            this.vendorAccountService.getiManageFolderContents(this.folderData.authToken, this.folderData.customerId, this.folderData.libraryId, this.folderData.folderId).subscribe((res) => {
                this.loading = false;
                if(res.result?.data){
                    this.subFolders = [];
                    this.documents = [];
                    res.result.data.forEach((docOrFolder: any) => {
                        if(this.folderData){
                            if(docOrFolder.folder_type){
                                this.subFolders.push({
                                    authToken: this.folderData.authToken, 
                                    customerId: this.folderData.customerId, 
                                    libraryId: docOrFolder.database,
                                    folderName: docOrFolder.name,
                                    folderId: docOrFolder.id, 
                                    expanded: false
                                });
                            }
                            else if(docOrFolder.class){
                               this.documents.push({
                                auth_token: this.folderData.authToken,
                                id: docOrFolder.id,
                                name: docOrFolder.name,
                                extension: docOrFolder.extension.toLowerCase(),
                                workspace_id: docOrFolder.workspace_id.replace(/[{}]/g, ''),
                                workspace_name: docOrFolder.workspace_name,
                                customerId: this.folderData.customerId,
                                libraryId: this.folderData.libraryId,
                                fileName: docOrFolder.name + '.' + docOrFolder.extension.toLowerCase(),
                                size: docOrFolder.size,
                                selected: false
                               });
                            } else {
                                console.log("Found unknown item:", res);
                            }
                        }
                    });
                }
                if(this.folderData){
                    this.folderData.expanded = true;
                    if(!skipDrilldown){
                        this.curDrilldown.push(this.folderData.folderId);
                        this.saveDrilldown();
                    }
                }
            });  
        } else {
            this.loading = false;
            this.folderData.expanded = false;
            if(!skipDrilldown){
                this.curDrilldown = this.curDrilldown.filter(a => a != this.folderData?.folderId);
                this.saveDrilldown();
            }
        }
    }

    public saveDrilldown(){
        let drilldown: string = "";
        this.curDrilldown.forEach( a => {
            drilldown += a + ",";
        });
        localStorage.setItem("folderDrilldown", drilldown);
    }

    public selectDocument(documentId: string){
        let document: DocumentDetails | undefined = this.documents.find(doc => doc.id == documentId);
        if(document && this.folderData){
            document.selected = !document.selected;
            this.documentSelection$?.next(document);
        }
    } 

    public sizeInMb(documentSize: string){
        let size = Number(documentSize);
        if(isNaN(size))
            return 'Unknown Size';
        if(size > 999999)
            return `${Math.round(Number(documentSize) / 10000) / 100} mb`;
        return `${Math.round(Number(documentSize) / 10) / 100} kb`;
    }
    
}


interface FolderRow{
    authToken: string, 
    customerId: string, 
    libraryId: string,
    folderName: string,
    folderId: string, 
    expanded: boolean
}

interface DocumentDetails {
    auth_token: string,
    id: string,
    name: string,
    extension: string,
    workspace_id: string,
    workspace_name: string,
    fileName: string,
    size: string,
    customerId: string,
    libraryId: string, 
    selected: boolean
}

